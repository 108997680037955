var $ = require('jquery');

module.exports = function($) {
  $.MenuAnchor = function (elem, options) {
    var self = this;

    // default settings
    self.settings = $.extend({
      window: window.top,
      pluralId: 'menu-anchor-',
      activeClass: 'is-active',
      contentPostfix: '-content',
      scroll: true,
      headerH: 0
    }, options);

    self.scroll = true;

    self.init = function () {

      function scrollToContent($el) {
        var pos = $el.offset();

        $(self.settings.window.document).find('html, body').animate(
            { scrollTop: pos.top - 5 - self.settings.headerH },
            {
              duration: 'slow',
              complete: function () {
                if (self.settings.scroll)
                  self.scroll = true;

                self.setSelectedLink($el[0].id.replace('-content', ''));
              },
              error: function () {
                if (self.settings.scroll)
                  self.scroll = true;
                self.setSelectedLink($el[0].id.replace('-content', ''));
              }
            }
        );
      }

      $(elem).find('[id^=' + self.settings.pluralId + ']').each(function (i, e) {
        // event click
        $(e).click(function (evt) {
          // evt.preventDefault();
          if (self.settings.scroll)
            self.scroll = false;

          scrollToContent($('#' + e.id + self.settings.contentPostfix))
        });
      });

      // scroll event
      if (self.settings.scroll) {
        $(self.settings.window).scroll(function () {
          if (self.scroll) {
            self.setSelectedLink();
          }
        });
      }

      if (location.hash) {
        scrollToContent($(location.hash))
      }

    };

    self.setSelectedLink = function (anchorId) {
      var currPos = -1;
      var currId = '';

      $(elem).find('[id^=' + self.settings.pluralId + ']').each(function (i, e) {
        if (typeof self.settings.window !== 'undefined') {
          // remove selected class
          if ($('#' + e.id).hasClass(self.settings.activeClass)) {
            $('#' + e.id).removeClass(self.settings.activeClass);
          }

          var xOffset = $('#' + e.id + self.settings.contentPostfix).offset();
          var yOffset = self.settings.window.pageYOffset;

          var scrPos = xOffset.top - yOffset;

          if ((scrPos >= 0 && currPos >= scrPos) || currPos <= 0 || currId === '') {
            currPos = scrPos;
            currId = e.id;
          }
        }

      });

      if (anchorId)
        currId = anchorId;

      if (currId !== '')
        $('#' + currId).addClass(self.settings.activeClass);

    };

    self.init();
    self.setSelectedLink();
  };

  $.fn.MenuAnchor = function (options) {
    return this.each(function () {
      (new $.MenuAnchor(this, options));
    });
  };
};
