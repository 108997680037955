require('./styles/app.scss');

var $ = require('jquery');
var MenuAnchor = require('./js/menu-anchor.js')($);
var MicroModal = require('micromodal').default;
var Cookies = require('js-cookie');
require('lazysizes');

function scrollBarWidth () {
    var div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
}

MicroModal.init({
    onShow (modal) {
        document.querySelector('body').classList.add('is-modal-opened');
        $('html, #header').css('padding-right', scrollBarWidth() + 'px');
    },
    onClose (modal) {
        document.querySelector('body').classList.remove('is-modal-opened');
        $('html, #header').css('padding-right', 0);
    }
});

(function() {
    var headerButton = $('.js-burger');
    var navigationButtons = $('.js-navigation').find('a');
    var menu = $('.js-menu');
    var overlay = $('.js-overlay');
    var footerButtons = $('.js-footer-title');

    headerButton.on('click', function() {
        $(this).toggleClass('active');
        menu.toggleClass('visible');
        overlay.toggleClass('visible');
    });

    overlay.on('click', function() {
        headerButton.removeClass('active');
        menu.removeClass('visible');
        overlay.removeClass('visible');
    });

    navigationButtons.on('click', function() {
        headerButton.removeClass('active');
        menu.removeClass('visible');
        overlay.removeClass('visible');
    });

    footerButtons.on('click', function() {
        $(this).next().toggleClass('visible');
    });

    // MENU SCROLLER
    if (location.pathname === '/') {
        $('.js-menu').MenuAnchor({
            window: window.top,
            pluralId: 'section-',
            activeClass: 'active',
            contentPostfix: '-content',
            headerH: $('#header').height() + 10,
            scroll: true
        });
    }

    // COOKIES PANEL
    function cookiesInit() {
        var FLAG = Cookies.get('cook');

        if (!FLAG) {
            document.body.classList.add('cookies-open');
        }

        document.querySelector('.js-cookies-close').addEventListener('click', function(e) {
            Cookies.set('cook', 1);
            document.body.classList.remove('cookies-open');
        }, false);
    }
    cookiesInit();

    // FORMS
    function serializeForm(form) {
        var formJson = {};
        var formElements = Array.prototype.slice.call(form.elements).filter(el => {
            return (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA' || el.tagName === 'SELECT')
        });
        formElements.forEach(el => {
            var fieldName = el.name;
            formJson[fieldName] = (el.value === '') ? null : el.value;

            if (el.type === 'checkbox' || el.type === 'radio') {
                formJson[fieldName] = el.checked;
            }
        });
        if (form.querySelector('.g-recaptcha')) {
            formJson['g-recaptcha-response'] = grecaptcha.getResponse(window[form.querySelector('.g-recaptcha').id]);
        }
        return JSON.stringify(formJson);
    }

    function clearForm(form) {
        var formElements = Array.prototype.slice.call(form.elements).filter(el => { return (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA' || el.tagName === 'SELECT') });

        if (form.querySelector('.g-recaptcha')) grecaptcha.reset(window[form.querySelector('.g-recaptcha').id]);

        formElements.forEach(el => {
            el.value = '';

            if (el.type === 'checkbox' || el.type === 'radio') {
                el.checked = false;
            }
        });
    }

    function showErrors(form, errors) {
        errors.forEach((el) => {
            var $erroredFieldset = $(form).find(`[name="${el.field}"]`).parents('fieldset');

            $erroredFieldset.addClass('is-error');
            $erroredFieldset.find('.input__error').html(el.message);
        });
    }

    function showThankModal(title, message) {
        var modal = document.querySelector('#thank-you-modal');

        modal.querySelector('#thank-you-modal-title').innerHTML = title;
        modal.querySelector('#thank-you-modal-message').innerHTML = message;

        MicroModal.show('thank-you-modal');
    }

    function setupSubscribeForm() {
        var NEWSLETTER_URL = 'https://api.aechannel.com/hprofits/users/subscribe';

        var subscribeForm = document.getElementById('subscribe-form');
        var subscribeBlock = $('.js-footer-subscribe');

        subscribeForm.querySelector('input').addEventListener('focus', function() {
            subscribeForm.querySelector('.footer__input').classList.remove('is-error');
        });

        subscribeForm.addEventListener('submit', function(e) {
            e.preventDefault();
            subscribeBlock.removeClass('success');

            $.post(
                NEWSLETTER_URL,
                serializeForm(subscribeForm))
                .always(function(response) {
                    if (response.status === 200) {
                        clearForm(subscribeForm);
                        showThankModal('Thank You For Subscribing!', '');
                    } else {
                        var error = response.responseJSON.error ? response.responseJSON.error.errors[0].message : 'Something went wrong, please try again later';
                        subscribeForm.querySelector('input').value = '';
                        subscribeForm.querySelector('.error').innerHTML = error;
                        subscribeForm.querySelector('.footer__input').classList.add('is-error');
                    }
                });

            return;
        });
    }

    function setupContactUsForm () {
        var CONTACT_US_URL = 'https://api.aechannel.com/hprofits/users/contact';

        var contactUsForm = document.getElementById('contact-us-form');
        var contactUsSuccess = $('.js-contacts-success');
        if (!contactUsForm) return;
        contactUsSuccess.hide();

        $('#contact-us-form').find('input, textarea').on('focus', function() {
            $(this).parent().removeClass('is-error');
        });

        contactUsForm.addEventListener('submit', function (e) {
            e.preventDefault();

            $.post(
                CONTACT_US_URL,
                serializeForm(contactUsForm))
                .always(function(response) {
                    if (response.status === 200) {
                        clearForm(contactUsForm);
                        contactUsSuccess.show();
                    } else {
                        showErrors(contactUsForm, response.responseJSON.error.errors);
                    }
                });

            return;
        });
    }

    if (localStorage.getItem('current-user') !== null) {
        var linkTemplate = $('#dashboard-link').html();
        $('.js-auth').find('.js-login').hide().end().append(linkTemplate);
    }

    setupContactUsForm();
    setupSubscribeForm();
})();
